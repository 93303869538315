import React from "react";
import clsx from "clsx";
import {
	Button,
	Toggle as MuiToggle,
	ToggleActions,
	ToggleContent,
	ToggleMedia,
	Container,
	Grid,
	Item,
	Typography,
	withStyles
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import TooltipWrapper from "../tooltip-wrapper";

const Toggle = ({
	classes,
	className,
	value,
	options,
	variable,
	onChange,
	...props
}) => {
	return (
		<ToggleButtonGroup
			value={value}
			exclusive
			onChange={onChange}
			className={clsx(classes.root, className)}
			aria-label=""
		>
			{options.map((d, i) => (
				<ToggleButton
					key={i}
					value={d.key}
					variant="contained"
					disabled={d.key === value}
					data-variable={variable}
				>
					<TooltipWrapper content={d.tooltip}>{d.label}</TooltipWrapper>
				</ToggleButton>
			))}
		</ToggleButtonGroup>
	);
};

Toggle.propTypes = {};

export default withStyles(theme => ({
	root: {
		"& .MuiToggleButtonGroup-root": {
			margin: theme.spacing(4, 0)
		},
		"& .MuiButtonBase-root": {
			textTransform: "unset",
			fontSize: theme.typography.pxToRem(14)
		},
		"& .MuiToggleButton-root": {
			padding: 0,
			backgroundColor: theme.palette.background.paper,
			borderColor: theme.palette.red.med,
			"& > div > div": {
				display: "inline-block",
				padding: theme.spacing(1, 3),
				pointerEvents: "all"
			},
			"&:hover": {
				backgroundColor: theme.palette.background.paperSecondary
			}
		},
		"& .MuiToggleButton-root.Mui-selected": {
			color: theme.palette.red.main,
			backgroundColor: theme.palette.red.light,
			borderColor: theme.palette.red.main,
			borderLeftColor: `${theme.palette.red.main} !important`,
			borderLeftWidth: "1px !important",
			position: "relative",
			zIndex: 10,
			"&:hover": {
				backgroundColor: theme.palette.red.light
			},
			"&:not(.Mui-disabled):hover": {
				backgroundColor: theme.palette.red.med
			}
		}
	},
	MuiToggleButtonGroup: {
		"&": {
			backgroundColor: theme.palette.red.main
		}
	}
}))(Toggle);
