import React from "react";
import { withStyles } from "@material-ui/core";
import { Container } from "@hyperobjekt/material-ui-website";

const Content = ({ children, ...props }) => {
	return <Container {...props}>{children}</Container>;
};

export default withStyles(theme => ({
	root: {
		margin: theme.spacing(12, "auto"),
		maxWidth: `45rem`,
		"& > .MuiTypography-root": {
			marginBottom: `1rem`,
			"& + .MuiList-root": {
				marginTop: 0
			}
		},
		"& .MuiListItem-root > .MuiTypography-root": {
			marginBottom: `0.5rem`
		},
		"& h2": {
			marginTop: `1.5em`,
			color: theme.palette.blue.header
		},
		"& .MuiLink-root": {
			fontSize: "inherit"
		},
		"& .MuiTypography-h2": {
			fontSize: theme.typography.pxToRem(32),
			margin: theme.spacing(4, 0)
		},
		"& .MuiTypography-h3": {
			fontSize: theme.typography.pxToRem(24),
			margin: theme.spacing(3, 0)
		},
		"& a": {
			color: theme.palette.text.primary,
			textDecoration: "underline",
			textUnderlineOffset: "3px",
			textDecorationColor: theme.palette.secondary.main
		},
		"& a.MuiButton-contained": {
			color: theme.palette.text.light,
			textDecoration: "none"
		}
	}
}))(Content);
