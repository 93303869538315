import React from "react";
import clsx from "clsx";
import { Box, Grid, Typography, withStyles } from "@material-ui/core";
import Tooltip from "../../common/tooltip";
import { formatDate, formatNumber } from "../../../utils";

const MONTHLY_DATE_OPTIONS = {
	year: "numeric",
	month: "long"
};

const WEEKLY_DATE_OPTIONS = {
	year: "numeric",
	day: "numeric",
	month: "long"
};

const ChartTooltip = ({
	value,
	data,
	settings,
	classes,
	className,
	children,
	...props
}) => {
	let varLabel =
		settings.variable.charAt(0).toUpperCase() + settings.variable.slice(1);

	// filings count: Filings
	// filings rate: Filings per 100 renter households

	return (
		<Tooltip
			className={clsx(classes.root, className)}
			style={{
				top: data.y,
				left: data.x
			}}
			{...props}
			header={`
        ${settings.interval === "weekly" ? "Week of " : ""}
        ${formatDate(
					data.date,
					settings.interval === "weekly"
						? WEEKLY_DATE_OPTIONS
						: MONTHLY_DATE_OPTIONS
				)}
      `}
		>
			<Box className={classes.stat}>
				<Grid container>
					<Grid item className={classes.value}>
						<Typography component="span">
							{settings.unit === "comparison"
								? `${value}%`
								: formatNumber(value)}
						</Typography>
					</Grid>
					<Grid item className={classes.unit}>
						<Typography component="div">
							{settings.unit === "comparison"
								? "percent of pre-pandemic average"
								: "filings"}
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</Tooltip>
	);
};

ChartTooltip.propTypes = {};

export default withStyles(theme => ({
	root: {
		minWidth: 200,
		position: "absolute",
		zIndex: 1,
		transform: `translate(${theme.spacing(2)}, ${theme.spacing(2)})`
	},
	stat: {
		marginBottom: theme.spacing(1),
		"& .MuiGrid-container": {
			flexWrap: "nowrap"
		}
	},
	value: {
		display: "inline-block",
		paddingRight: theme.spacing(1)
	},
	unit: {
		maxWidth: theme.spacing(14),
		margin: "auto 0",
		"& .MuiTypography-root": {
			fontSize: theme.typography.pxToRem(10),
			fontWeight: theme.typography.body.semibold.fontWeight
		}
	}
}))(ChartTooltip);
