import React, { useCallback, useRef, useState, useEffect } from "react";
import { navigate, graphql, useStaticQuery } from "gatsby";
import { useTheme, withStyles } from "@material-ui/core";
import clsx from "clsx";
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { getUrl } from "../../utils";

import MapToolbar from "./components/toolbar";
import MapTooltip from "./components/tooltip";
import MapLegend from "./components/legend";

const MAPBOX_TOKEN =
	"pk.eyJ1IjoiaHlwZXJvYmpla3QiLCJhIjoiY2pzZ3Bnd3piMGV6YTQzbjVqa3Z3dHQxZyJ9.rHobqsY_BjkNbqNQS4DNYw";
const MAPBOX_STYLE = "mapbox://styles/hyperobjekt/cl62m709c000p15kan4f8c7um";
const MAPBOX_TILESET_MERCATOR = "mapbox://hyperobjekt.lsc-mercator-production";
const MAPBOX_SOURCE_LAYER_MERCATOR = "mercator";
const MAPBOX_TILESET_ALBERS = "mapbox://hyperobjekt.lsc-albers-production";
const MAPBOX_SOURCE_LAYER_ALBERS = "albers";

const MAPBOX_SOURCE = "lsc_source";
const PROJECTION = "mercator";
const PROMOTE_ID = "fips";
const MAP_VAR = "filings";
const FILINGS_RATE_KEY = "filings_rate";
const FILINGS_COUNT_KEY = "filings_count";
const FILINGS_LATEST_KEY = "filings_latest";
const FILINGS_KEYS = [FILINGS_RATE_KEY];
const DEFAULT_FILINGS_KEY = FILINGS_RATE_KEY;
const DEFAULT_MAX_FILINGS = 50;
const DEFAULT_BOUNDS_PADDING = 1;
const DEFAULT_LNG = -90.91;
const DEFAULT_LAT = 35.22;

const Map = ({
	classes,
	className,
	level,
	stateName,
	stateFips,
	stateShort,
	countyFips,
	multiCountyFips,
	interactive,
	bbox,
	albers,
	isInHero,
	selectOptions,
	showStateSelect,
	showKeyToggle,
	showOverlayToggle,
	showLegend,
	showMarker,
	latestMonth,
	padding,
	maxVal,
	data,
	...props
}) => {
	const theme = useTheme();

	const [mapLoaded, setMapLoaded] = useState(false);
	const [currentFeature, setCurrentFeature] = useState(false);
	const [hoveredFeature, setHoveredFeature] = useState(null);
	const [showTooltip, setShowTooltip] = useState(false);
	const [settings, setSettings] = useState({});
	const [windowWidth, setWindowWidth] = useState(null);
	const [windowHeight, setWindowHeight] = useState(null);

	const hoveredFeatureId = useRef(null);
	const statesData = useRef(null);
	const countiesData = useRef(null);
	const maxVals = useRef({});

	const rootElem = useRef(null);
	const mapContainerElem = useRef(null);
	const mapElem = useRef(null);
	const map = useRef(null);

	const [lng, setLng] = useState(DEFAULT_LNG);
	const [lat, setLat] = useState(DEFAULT_LAT);
	const [zoom, setZoom] = useState(0);

	const tileset =
		level === "nation" ? MAPBOX_TILESET_ALBERS : MAPBOX_TILESET_MERCATOR;
	const sourceLayer =
		level === "nation"
			? MAPBOX_SOURCE_LAYER_ALBERS
			: MAPBOX_SOURCE_LAYER_MERCATOR;

	mapboxgl.accessToken = MAPBOX_TOKEN;

	useEffect(() => {
		setUpMapbox();

		const handleResize = () => {
			setWindowWidth(window.innerWidth);
			setWindowHeight(window.innerHeight);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		zoomToFeature();
	}, [windowWidth]);

	useEffect(() => {
		if (!mapLoaded) return;
		const serviceAreasVis = settings.serviceAreas ? "visible" : "none";
		if (map.current.getLayer("service-areas-fill")) {
			map.current.setLayoutProperty(
				"service-areas-fill",
				"visibility",
				serviceAreasVis
			);
		}
		if (map.current.getLayer("service-areas-line")) {
			map.current.setLayoutProperty(
				"service-areas-line",
				"visibility",
				serviceAreasVis
			);
		}

		if (map.current.getLayer("service-areas-fill")) {
			map.current.setLayoutProperty(
				"service-areas-fill",
				"visibility",
				serviceAreasVis
			);
		}
		if (map.current.getLayer("service-areas-line")) {
			map.current.setLayoutProperty(
				"service-areas-line",
				"visibility",
				serviceAreasVis
			);
		}

		countiesData.current.forEach(row => {
			let filings = Number(row[DEFAULT_FILINGS_KEY]);
			map.current.setFeatureState(
				{
					source: MAPBOX_SOURCE,
					sourceLayer: sourceLayer,
					id: Number(row.fips)
				},
				{
					filings: filings
				}
			);
		});
	}, [, settings]);

	useEffect(() => {
		if (!mapLoaded) return;
		// If mouse leaves features
		if (!hoveredFeature) {
			toggleFeatureHover(hoveredFeatureId.current, false);
		}
		// If mouse goes from one feature to another
		if (hoveredFeature && hoveredFeature.id !== hoveredFeatureId.current) {
			toggleFeatureHover(hoveredFeatureId.current, false);
		}
		// If mouse enters new feature
		if (hoveredFeature !== null) {
			toggleFeatureHover(hoveredFeature.id, true, hoveredFeature.empty);
		}
	}, [hoveredFeature, hoveredFeatureId]);

	const toggleFeatureHover = (id, hover, empty) => {
		map.current.setFeatureState(
			{
				source: MAPBOX_SOURCE,
				sourceLayer: sourceLayer,
				id: id
			},
			{
				hover: hover && !empty
			}
		);
		setShowTooltip(hover ? true : false);
		hoveredFeatureId.current = hover && !empty ? id : null;
		mapElem.current.style.cursor = hover && !empty ? "pointer" : "";
	};

	const setUpMapbox = () => {
		if (map.current) return; // Initialize map only once
		map.current = new mapboxgl.Map({
			container: mapContainerElem.current,
			style: MAPBOX_STYLE,
			center: [lng, lat],
			projection: PROJECTION,
			zoom: zoom,
			interactive: interactive ? true : false,

			logoPosition: "bottom-right"

		});

		map.current.scrollZoom.disable();
		map.current.doubleClickZoom.disable();
		map.current.dragPan.disable();
		map.current.dragRotate.disable();

		mapElem.current = map.current.getCanvas();

		map.current.on("load", async () => {
			loadNationalSources();
			setMapLoaded(true);
		});

		let newCurrentFeature = null;
		map.current.on("render", () => {
			if (newCurrentFeature) return;
			if (!map.current.getLayer("states-line")) return;
			const features = map.current.queryRenderedFeatures({
				layers: ["states-line"],
				filter:
					level === "nation"
						? ["==", ["get", "type"], "state"]
						: ["==", ["get", "name"], stateName]
			});
			if (features.length) {
				newCurrentFeature = features;
				setCurrentFeature(newCurrentFeature);
			}
		});
	};

	const loadNationalSources = () => {
		map.current.addSource(MAPBOX_SOURCE, {
			type: "vector",
			url: tileset,
			promoteId: PROMOTE_ID
		});
	};

	// const getStatesData = async () => {
	// 	return await fetch("/data/states.json")
	// 		.then(res => res.json())
	// 		.then(json => {
	// 			statesData.current =
	// 				level === "state" ? json.filter(s => s.fips === stateFips) : json;
	// 		});
	// };

	// const getCountiesData = async () => {
	// 	return await fetch("/data/counties.json")
	// 		.then(res => res.json())
	// 		.then(json => {
	// 			countiesData.current =
	// 				level === "state"
	// 					? json.filter(s => s.state_fips === stateFips)
	// 					: json;
	// 			FILINGS_KEYS.forEach(k => {
	// 				maxVals.current[k] =
	// 					maxVal ||
	// 					Math.max(...countiesData.current.map(row => row[k])) ||
	// 					DEFAULT_MAX_FILINGS;
	// 			});
	// 		});
	// };

	const addMapData = async () => {
		countiesData.current.forEach(row => {
			// add each county in the dataset AND any counties that share its data (where the data apply to multiple counties)
			const alsoAppliesTo =
				row.fips_if_multi_county.length > 0
					? row.fips_if_multi_county
							.split(",")
							.filter(x => Number(x) !== Number(row.fips))
					: [];

			for (const f of [row.fips, ...alsoAppliesTo]) {
				map.current.setFeatureState(
					{
						source: MAPBOX_SOURCE,
						sourceLayer: sourceLayer,
						id: Number(f)
					},
					{
						[MAP_VAR]: Number(row[DEFAULT_FILINGS_KEY])
					}
				);
			}
		});

		map.current.addLayer({
			id: "states-fill",
			type: "fill",
			source: MAPBOX_SOURCE,
			"source-layer": sourceLayer,
			paint: {
				"fill-color": isInHero
					? level === "nation"
						? ["case", ["==", ["get", "fips"], stateFips], "#004EED", "#0A3FAE"]
						: "transparent"
					: "transparent"
			},
			filter: [
				"all",
				["==", ["get", "type"], "state"],
				isInHero ? ["!=", ["get", "territory"], true] : true
			]
		});

		const countyHeroFillExpression = () => {
			/**
			 * Returns a Mapbox expression with color fill for any county the data applies to (some data are for multiple counties combined)
			 */
			let exp = ["case"];
			if (multiCountyFips && multiCountyFips.length > 0) {
				multiCountyFips.split(",").forEach(x => {
					exp.push(["==", ["get", "fips"], Number(x)], "#004EED");
				});
			} else {
				exp.push(["==", ["get", "fips"], Number(countyFips)]);
			}
			exp.push(
				[
					"case",
					["==", ["get", "state_fips"], Number(stateFips)],
					"#0A3FAE",
					"transparent"
				],
				"#0A3FAE"
			);
			return exp;
		};

		FILINGS_KEYS.map((key, i) => {
			map.current.addLayer({
				id: `counties-${key}-fill`,
				type: "fill",
				source: MAPBOX_SOURCE,
				"source-layer": sourceLayer,
				layout: {
					visibility: i === 0 ? "visible" : "none"
				},
				paint: {
					"fill-color": isInHero
						? level === "state"
							? countyHeroFillExpression()
							: [
									"case",
									["==", ["get", "fips"], Number(countyFips)],
									"#004EED",
									"transparent"
							  ]
						: [
								"case",
								["!=", ["feature-state", MAP_VAR], null],
								[
									"interpolate",
									["linear"],
									["to-number", ["feature-state", MAP_VAR]],
									0,
									theme.palette.map.fill.min,
									maxVals.current[key],
									theme.palette.map.fill.max
								],
								theme.palette.map.fill.default
						  ],
					"fill-opacity":
						level === "state"
							? ["case", ["==", ["get", "state_fips"], Number(stateFips)], 1, 0]
							: isInHero
							? 0
							: 1
				},
				filter: ["==", ["get", "type"], "county"]
			});
		});

		map.current.addLayer({
			id: "counties-line",
			type: "line",
			source: MAPBOX_SOURCE,
			"source-layer": sourceLayer,
			paint: {
				"line-color": isInHero
					? level === "state"
						? [
								"case",
								["==", ["get", "state_fips"], Number(stateFips)],
								theme.palette.blue.dark,
								"transparent"
						  ]
						: "transparent"
					: level === "state"
					? [
							"case",
							["==", ["get", "state_fips"], Number(stateFips)],
							theme.palette.map.stroke.default,
							theme.palette.map.stroke.hidden
					  ]
					: [
							"case",
							["!=", ["feature-state", MAP_VAR], null],
							theme.palette.map.stroke.default,
							theme.palette.map.stroke.empty
					  ],
				"line-width": level === "nation" ? 0.5 : 1
			},
			filter: ["==", ["get", "type"], "county"]
		});

		map.current.addLayer({
			id: "states-line",
			type: "line",
			source: MAPBOX_SOURCE,
			"source-layer": sourceLayer,
			paint: {
				"line-color": isInHero
					? theme.palette.blue.dark
					: level === "state"
					? [
							"case",
							["==", ["get", "fips"], stateFips],
							theme.palette.map.stroke.default,
							theme.palette.map.stroke.hidden
					  ]
					: level === "nation"
					? theme.palette.map.stroke.dark
					: theme.palette.map.stroke.hidden,
				"line-width": 1
				// level === "nation" ? 1.5 : 1,
			},
			filter: ["==", ["get", "type"], "state"]
		});

		map.current.addLayer({
			id: "service-areas-fill",
			type: "fill",
			source: MAPBOX_SOURCE,
			"source-layer": sourceLayer,
			paint: {
				"fill-color": theme.palette.red.main,
				"fill-opacity": 0.075
			},
			layout: {
				visibility: "none"
			},
			filter: stateShort
				? [
						"all",
						["==", ["get", "type"], "service-area"],
						["==", ["get", "state_short"], stateShort]
				  ]
				: ["all", ["==", ["get", "type"], "service-area"]]
		});

		map.current.addLayer({
			id: "service-areas-line",
			type: "line",
			source: MAPBOX_SOURCE,
			"source-layer": sourceLayer,
			paint: {
				"line-color": theme.palette.red.med,
				"line-width": [
					"case",
					["boolean", ["feature-state", "hover"], false],
					2,
					1
				]
			},
			layout: {
				visibility: "none"
			},
			filter: stateShort
				? [
						"all",
						["==", ["get", "type"], "service-area"],
						["==", ["get", "state_short"], stateShort]
				  ]
				: ["all", ["==", ["get", "type"], "service-area"]]
		});

		if (!isInHero) {
			if (level === "nation") {
				map.current.addLayer({
					id: "states-line-hover",
					type: "line",
					source: MAPBOX_SOURCE,
					"source-layer": sourceLayer,
					paint: {
						"line-color": [
							"case",
							["boolean", ["feature-state", "hover"], false],
							theme.palette.map.stroke.hover,
							"transparent"
						],
						"line-width": [
							"case",
							["boolean", ["feature-state", "hover"], false],
							2,
							0
						]
					},
					filter: ["==", ["get", "type"], "state"]
				});
			}
			if (level === "state") {
				map.current.addLayer({
					id: "counties-line-hover",
					type: "line",
					source: MAPBOX_SOURCE,
					"source-layer": sourceLayer,
					paint: {
						"line-color": [
							"case",
							["boolean", ["feature-state", "hover"], false],
							theme.palette.map.stroke.hover,
							"transparent"
						],
						"line-width": [
							"case",
							["boolean", ["feature-state", "hover"], false],
							2,
							0
						]
					},
					filter: ["==", ["get", "type"], "county"]
				});
			}
		}

		let countyHeroLineExpression = () => {
			/**
			 * Returns a Mapbox expression to display the outline of any county the data applies to (some data are for multiple counties combined)
			 */
			let exp = ["all", ["==", ["get", "type"], "county"]];
			let any = ["any"];
			if (multiCountyFips && multiCountyFips.length > 0) {
				multiCountyFips.split(",").forEach(x => {
					any.push(["==", ["get", "fips"], Number(x)]);
				});
				exp.push(any);
			} else {
				exp.push(["==", ["get", "fips"], Number(countyFips)]);
			}
			return exp;
		};

		if (isInHero) {
			if (level === "nation") {
				map.current.addLayer({
					id: "states-line-active",
					type: "line",
					source: MAPBOX_SOURCE,
					"source-layer": sourceLayer,
					paint: {
						"line-color": theme.palette.red.main,
						"line-width": 2,
						"line-opacity": 1
					},
					filter: [
						"all",
						["==", ["get", "type"], "state"],
						["==", ["get", "fips"], stateFips]
					]
				});
			}
			if (level === "state") {
				map.current.addLayer({
					id: "counties-line-active",
					type: "line",
					source: MAPBOX_SOURCE,
					"source-layer": sourceLayer,
					paint: {
						"line-color": theme.palette.red.main,
						"line-width": 2,
						"line-opacity": 1
					},
					filter: countyHeroLineExpression()
				});
			}
		}
	};

	const handleMouseMove = useCallback(
		e => {
			const feature = e.features.find(f => {
				let type;
				if (settings.serviceAreas) {
					type = "service-area";
				} else if (interactive === "states") {
					type = "state";
				} else if (interactive === "counties") {
					type = "county";
				}
				return f.properties.type === type;
			});
			if (!feature) return;
			let featureData;
			let featureId = feature.id;
			if (interactive === "states") {
				featureData = statesData.current.filter(
					s => s.fips === feature.properties.fips
				);
			}
			if (interactive === "counties") {
				if (!settings.serviceAreas) {
					// Find matching fips in the counties data
					featureData = countiesData.current.filter(
						c => c.fips === feature.properties.fips
					);
					// If no matching fips but is part of a multi-county data situation, find the applicable row
					const rowIfMultiCounty = countiesData.current.filter(
						c =>
							c.fips_if_multi_county
								.split(",")
								.map(x => Number(x))
								.indexOf(feature.properties.fips) > -1
					);
					if (rowIfMultiCounty.length > 0) {
						featureData = countiesData.current.filter(
							c => c.fips === rowIfMultiCounty[0].fips
						);
					}
				} else if (feature.properties.type === "service-area") {
					featureId = feature.properties.rin;
					featureData = [
						{
							...feature.properties
						}
					];
				}
			}
			if (featureData && featureData.length) {
				setHoveredFeature({
					...e.point,
					...feature.properties,
					...featureData[0],
					id: featureId
				});
			} else if (level === "nation") {
				setHoveredFeature({
					...e.point,
					...feature.properties,
					id: featureId,
					empty: true
				});
			} else if (
				level === "state" &&
				feature.properties.state_fips === stateFips
			) {
				setHoveredFeature({
					...e.point,
					...feature.properties,
					id: featureId,
					empty: true
				});
			} else {
				setHoveredFeature(null);
			}
		},
		[settings, interactive]
	);

	const handleMouseLeave = useCallback(e => {
		setHoveredFeature(null);
	}, []);

	const handleClick = useCallback(
		e => {
			if (settings.serviceAreas) return;
			const feature = e.features.length > 0 ? e.features[0] : null;
			let featureData;
			if (interactive === "states") {
				featureData = statesData.current.find(
					s => s.fips === feature.properties.fips
				);
			}
			if (interactive === "counties") {
				featureData = countiesData.current.find(
					c => c.fips === feature.properties.fips
				);
				// Handle where multiple counties share the same data
				const rowIfMultiCounty = countiesData.current.filter(
					c =>
						c.fips_if_multi_county
							.split(",")
							.map(x => Number(x))
							.indexOf(feature.properties.fips) > -1
				);
				if (rowIfMultiCounty.length > 0) {
					featureData = countiesData.current.find(
						c => c.fips === rowIfMultiCounty[0].fips
					);
				}
			}
			if (featureData) {
				const { type } = feature.properties;
				const { name } = featureData;
				const url = getUrl("data", "eviction", stateName, name);
				navigate(url);
			}
		},
		[settings]
	);

	const zoomToFeature = () => {
		map.current.fitBounds(bbox, {
			duration: 0,
			padding: padding || DEFAULT_BOUNDS_PADDING
		});
	};

	useEffect(() => {
		if (interactive) {
			const interactiveLayers = [
				interactive,
				...(interactive === "counties"
					? [...FILINGS_KEYS.map(k => `${interactive}-${k}`), "service-areas"]
					: [])
			];
			interactiveLayers.forEach(layerKey => {
				map.current.on("mousemove", `${layerKey}-fill`, handleMouseMove);
				map.current.on("mouseleave", `${layerKey}-fill`, handleMouseLeave);
				map.current.on("click", `${layerKey}-fill`, handleClick);
			});
			return () => {
				interactiveLayers.forEach(layerKey => {
					map.current.off("mousemove", `${layerKey}-fill`, handleMouseMove);
					map.current.off("mouseleave", `${layerKey}-fill`, handleMouseLeave);
					map.current.off("click", `${layerKey}-fill`, handleClick);
				});
			};
		}
	}, [settings]);

	useEffect(() => {
		if (!data || !mapLoaded) return;
		statesData.current = data.states || [];
		countiesData.current = data.counties || [];
		FILINGS_KEYS.forEach(k => {
			maxVals.current[k] =
				maxVal ||
				Math.max(...countiesData.current.map(row => row[k])) ||
				DEFAULT_MAX_FILINGS;
		});
		addMapData();
	}, [data, mapLoaded]);

	return (
		<div ref={rootElem} className={clsx(classes.root, className)}>
			<MapToolbar
				settings={settings}
				setSettings={setSettings}
				showStateSelect={showStateSelect}
				showKeyToggle={showKeyToggle}
				showOverlayToggle={showOverlayToggle}
				selectOptions={selectOptions}
				latestMonth={latestMonth}
			/>

			<div ref={mapContainerElem} />

			{hoveredFeature ? (
				<MapTooltip
					level={level}
					x={hoveredFeature.x}
					y={hoveredFeature.y}
					data={{
						type: hoveredFeature.type,
						filings: hoveredFeature[DEFAULT_FILINGS_KEY],
						filings_count: hoveredFeature[FILINGS_COUNT_KEY],
						name: hoveredFeature.name,
						counties_tracked: hoveredFeature.counties_tracked,
						counties_total: hoveredFeature.counties_total
					}}
					visible={showTooltip}
				/>
			) : (
				""
			)}

			{showLegend && maxVals.current[DEFAULT_FILINGS_KEY] ? (
				<MapLegend
					value={hoveredFeature ? hoveredFeature[DEFAULT_FILINGS_KEY] : null}
					maxVals={maxVals.current[DEFAULT_FILINGS_KEY]}
					showMarker={showMarker}
				/>
			) : null}
		</div>
	);
};

Map.defaultProps = {
	data: {}
};
Map.propTypes = {};

export default withStyles(theme => ({
	root: {
		height: "100%",
		minHeight: 600,
		position: "relative",
		"& .mapboxgl-canvas": {
			width: "100% !important",
			height: "100% !important",
			cursor: "default"
		},
		"& .mapboxgl-map": {
			height: 600
		}
	}
}))(Map);
