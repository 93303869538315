import { createTheme } from "@material-ui/core/styles";
import { deepmerge } from "@material-ui/utils";

export const BASE_FONT_SIZE = 16;

export const COLORS = {
	red: "#E1251B",
	redLight: "#FEF7F6",
	redMed: "#D73823",
	blue: "#0096FB",
	blueLight: "#79AFDE",
	blueMed: "#5390C5",
	blueDark: "#001F60",
	blueHeader: "#3360A3",
	primary: "#D73823",
	secondary: "#2D2F85",
	paper: "#FFFFFF",
	paperSecondary: "#F4F4F4",
	default: "#FFFFFF",
	primaryText: "#3B3B43",
	secondaryText: "#595247",
	altBackground: "#FAFAF5",
	darkBackground: "#181817",
	hoverExpandBio: "#CB4D38"
};

export const FONT_FAMILIES = {
	body: "'HK Grotesk', sans-serif",
	display: "'Open Sauce Sans', sans-serif"
};

export const FONT_WEIGHTS = {
	body: {
		light: 300,
		regular: 400,
		semibold: 600,
		bold: 700
	},
	display: {
		bold: 700
	}
};

export const FONTS = {
	body: {
		light: {
			fontFamily: FONT_FAMILIES.body,
			fontWeight: FONT_WEIGHTS.body.light
		},
		regular: {
			fontFamily: FONT_FAMILIES.body,
			fontWeight: FONT_WEIGHTS.body.regular
		},
		semibold: {
			fontFamily: FONT_FAMILIES.body,
			fontWeight: FONT_WEIGHTS.body.semibold
		},
		bold: {
			fontFamily: FONT_FAMILIES.body,
			fontWeight: FONT_WEIGHTS.body.bold
		}
	},
	display: {
		bold: {
			fontFamily: FONT_FAMILIES.display,
			fontWeight: FONT_WEIGHTS.display.bold
		}
	}
};

/** Spacing function */
export const spacing = factor => `${0.5 * factor}rem`;

/** Create theme */
let theme = createTheme({
	palette: {
		primary: {
			main: COLORS.primaryText
		},
		secondary: {
			main: COLORS.secondaryText
		},
		blue: {
			main: COLORS.blue,
			light: COLORS.blueLight,
			med: COLORS.blueMed,
			dark: COLORS.blueDark,
			header: COLORS.blueHeader
		},
		red: {
			main: COLORS.red,
			light: COLORS.redLight,
			med: COLORS.redMed
		},
		text: {
			primary: COLORS.primaryText,
			secondary: COLORS.secondaryText,
			error: COLORS.red,
			info: COLORS.secondaryText,
			success: COLORS.blueLight,
			warning: COLORS.redLight,
			light: "#fff"
		},
		background: {
			default: COLORS.default,
			paper: COLORS.paper,
			paperSecondary: COLORS.paperSecondary,
			paperOverlay: "rgba(255,255,255,0.5)",
			dark: COLORS.darkBackground,
			alt: COLORS.altBackground
		},
		gradient: {
			top: "linear-gradient(to top, transparent, rgba(0,0,0,.7))",
			bottom: "linear-gradient(to bottom, transparent, rgba(0,0,0,.7))",
			left: "linear-gradient(to left, transparent, rgba(0,0,0,.7))",
			right: "linear-gradient(to right, transparent, rgba(0,0,0,.7))"
		},
		map: {
			fill: {
				default: "#FAFAFA",
				min: "#E8F5FF",
				mid: "#5390C5",
				max: "#2D5C85"
			},
			stroke: {
				default: "#CCCCCC",
				dark: "#adadad",
				hover: COLORS.red,
				outline: "#284F71",
				empty: "#DDDDDD",
				hidden: "transparent"
			}
		}
	},
	shape: {
		borderRadius: 20,
		tooltipRadius: 5,
		buttonRadius: 3
	},
	layout: {
		headerHeight: 80
	},
	typography: {
		fontSize: BASE_FONT_SIZE,
		lineHeight: 1.2,
		fontFamily: FONT_FAMILIES.body,
		fontWeight: FONT_WEIGHTS.body.regular,
		...FONTS
	},
	spacing
});

/** Site wide global style overrides */
const overrides = {
	MuiCssBaseline: {
		"@global": {
			html: {
				fontSize: BASE_FONT_SIZE,
				scrollBehavior: "smooth",
				...theme.typography.body.regular,
				"& blockquote .MuiTypography-body1": {
					padding: theme.spacing(3, 0, 3, 6),
					fontSize: theme.typography.pxToRem(24),
					...theme.typography.display.bold
				}
			},
			p: {
				fontSize: theme.typography.pxToRem(16)
			},
			a: {
				textDecoration: "none"
			},
			blockquote: {
				"& p": {
					fontSize: theme.typography.pxToRem(28)
				}
			}
		}
	},
	MuiTypography: {
		root: {
			fontSize: BASE_FONT_SIZE
		},
		body1: {
			fontSize: theme.typography.pxToRem(16),
			lineHeight: 1.5,
			...theme.typography.body.regular
		},
		h1: {
			fontSize: theme.typography.pxToRem(50),
			...theme.typography.display.bold
		},
		h2: {
			fontSize: theme.typography.pxToRem(40),
			...theme.typography.display.bold
		},
		h3: {
			fontSize: theme.typography.pxToRem(32),
			...theme.typography.display.bold
		},
		h4: {
			fontSize: theme.typography.pxToRem(24),
			...theme.typography.display.bold
		},
		h5: {
			fontSize: theme.typography.pxToRem(18),
			...theme.typography.display.bold
		},
		h6: {
			fontSize: theme.typography.pxToRem(13),
			textTransform: "uppercase",
			...theme.typography.body.bold
		},
		subtitle1: {
			fontSize: theme.typography.pxToRem(13),
			textTransform: "uppercase",
			...theme.typography.body.bold
		},
		paragraph: {
			maxWidth: "32em"
		},
		gutterBottom: {
			marginBottom: theme.spacing(4)
		}
	},
	MuiContainer: {
		root: {
			paddingLeft: theme.spacing(3),
			paddingRight: theme.spacing(3)
		}
	},
	MuiPaper: {
		rounded: {
			borderRadius: theme.shape.tooltipRadius
		}
	},
	MuiTooltip: {
		tooltip: {
			backgroundColor: theme.palette.background.dark,
			padding: theme.spacing(2),
			fontSize: theme.typography.pxToRem(14),
			...theme.typography.body.regular,
			"& a": {
				color: "#fff",
				textDecoration: "underline"
			}
		},
		arrow: {
			color: theme.palette.background.dark
		}
	},
	MuiButton: {
		root: {
			minWidth: "unset",
			display: "inline-block",
			textTransform: "unset",
			textDecoration: "none",
			textAlign: "left",
			boxShadow: "none !important",
			borderRadius: theme.shape.buttonRadius,
			lineHeight: 1,
			"& .MuiTouchRipple-root": {
				transition: theme.transitions.create(["opacity"])
			}
		},
		label: {
			fontSize: theme.typography.pxToRem(14)
		},
		contained: {
			padding: theme.spacing(1.5, 3),
			backgroundColor: theme.palette.red.main,
			color: theme.palette.text.light,
			"&:hover, &:focus-within": {
				backgroundColor: theme.palette.red.med
			}
		},
		outlined: {
			padding: theme.spacing(1.5, 3),
			borderWidth: 1,
			borderStyle: "solid",
			borderColor: "currentColor",
			color: theme.palette.red.main,
			"&:hover, &:focus-within": {
				"& .MuiTouchRipple-root": {
					opacity: 0.1
				}
			}
		},
		text: {
			padding: theme.spacing(1, 1, 1.4, 1),
			margin: theme.spacing(0, -1, 0, -1),
			textTransform: "unset",
			textDecoration: "none",
			borderRadius: 0,
			position: "relative",
			transition: theme.transitions.create(["color"], { duration: 300 }),
			"& .MuiButton-label": {
				display: "inline-block",
				paddingRight: theme.spacing(2),
				"&::after": {
					content: `""`,
					padding: "3px",
					marginTop: "-1.5px",
					display: "inline-block",
					position: "absolute",
					border: "solid",
					borderWidth: "0 1px 1px 0",
					transform: `rotate(-45deg) translate(${theme.spacing(0)}, 150%)`
				}
			},
			"& .MuiTouchRipple-root": {
				backgroundColor: "currentColor",
				opacity: 0.03
			},
			"&:hover, &:focus-within": {
				color: theme.palette.red.main,
				"& .MuiTouchRipple-root": {
					opacity: 0.1
				}
			}
		}
	},
	MuiToggleButton: {
		root: {
			borderRadius: `${theme.shape.buttonRadius}px !important`
		}
	},
	MuiCard: {
		root: {
			padding: theme.spacing(12, 0),
			borderRadius: 0,
			boxShadow: "none"
		}
	},
	MuiCardActions: {
		root: {
			padding: theme.spacing(2, 0)
		}
	},
	MuiFormControlLabel: {
		root: {
			"& .MuiTypography-root": {
				fontSize: theme.typography.pxToRem(12)
			}
		}
	},
	MuiAccordion: {
		root: {
			boxShadow: "none",
			"&:before, &.Mui-expanded + .MuiAccordion-root:before": {
				display: "block !important",
				opacity: "1 !important"
			}
		}
	},
	MuiAccordionSummary: {
		root: {
			"&.Mui-expanded": {
				minHeight: 0
			}
		},
		content: {
			"&.Mui-expanded": {
				padding: 0
			}
		}
	},
	MuiDrawer: {
		root: {
			"& nav > ul > li > a": {
				pointerEvents: "none",
				"& > svg": {
					display: "none"
				}
			}
		}
	},
	HypBreadcrumb: {
		root: {
			width: "100%",
			position: "absolute",
			"&, & a": {
				color: theme.palette.text.light
			},
			"& .HypNavigation-list": {
				flexWrap: "wrap"
			},
			"& nav > ul > li > .HypNavigation-link": {
				fontWeight: theme.typography.body.bold.fontWeight
			}
		},
		depth1: {
			maxHeight: theme.spacing(38),
			"& .HypNavigation-listItem": {
				backgroundColor: theme.palette.background.paperSecondary,
				"& a:hover": {
					color: theme.palette.red.main,
					textDecoration: "none"
				}
			},
			"& .HypNavigation-link": {
				color: theme.palette.text.primary
			}
		}
	}
};

export default deepmerge(theme, { overrides });
