import React from "react";
import { withStyles } from "@material-ui/core";

const JumpTarget = ({ id, children, classes, className, ...props }) => {
	return (
		<div className={classes.root}>
			<div id={id} className={classes.anchor} />
			{children}
		</div>
	);
};

JumpTarget.propTypes = {};

export default withStyles(theme => ({
	root: {},
	anchor: {
		position: "relative",
		top: -theme.layout.headerHeight * 2,
		pointerEvents: "none"
	}
}))(JumpTarget);
