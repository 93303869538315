import { withStyles } from "@material-ui/core";
import MobileNavigation from "gatsby-theme-hypersite/src/header/mobile-navigation";

export default withStyles(theme => ({
	root: {
		"& .MuiSvgIcon-root": {
			fill: theme.palette.blue.dark
		},
		"& .MuiButton-text .MuiButton-label::after": {
			display: "none"
		}
	},
	button: {},
	drawer: {
		"& .MuiDrawer-paper": {
			background: theme.palette.background.paper
		},
		"& .HypNavigation-link": {
			color: theme.palette.blue.dark,
			textDecoration: "none",
			...theme.typography.body.semibold,
			transition: theme.transitions.create(["background-color"])
		}
	},
	close: {
		color: theme.palette.blue.dark
	}
}))(MobileNavigation);
