import React from "react";
import {
	Typography,
	Box,
	withStyles,
	useTheme,
	useMediaQuery
} from "@material-ui/core";
import clsx from "clsx";
import Block from "gatsby-theme-hypersite/block";
import Table from "../../table";
import { COLORS } from "../../../theme";

const StateTable = ({ classes, className, rows, ...props }) => {
	const schema = [
		{
			key: "name",
			label: "County"
		},
		{
			key: "filings",
			label: "Filings"
		}
	];

	return (
		<Box className={clsx(classes.root, className)} {...props}>
			<Typography component="h2" className={classes.heading}>
				Eviction Filings by Jurisdiction
			</Typography>

			<Table
				schema={schema}
				data={rows}
				palette={{
					background: COLORS.dark,
					text: COLORS.light
				}}
			/>
		</Box>
	);
};

StateTable.propTypes = {};

export default withStyles(theme => ({
	root: {
		paddingLeft: theme.spacing(6),
		paddingRight: theme.spacing(6),
		paddingTop: theme.spacing(12),
		paddingBottom: theme.spacing(12),
		backgroundColor: theme.palette.background.darkBackground,
		color: theme.palette.background.paper,
		"& .MuiTableCell-stickyHeader": {
			backgroundColor: theme.palette.background.darkBackground
		},
		"& *": {
			color: theme.palette.background.paper
		}
	},
	heading: {
		fontSize: theme.typography.pxToRem(18),
		...theme.typography.body.bold
	}
}))(StateTable);
