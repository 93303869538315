import React from "react";
import { Link } from "gatsby";
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Container,
	Grid,
	Item,
	Typography,
	withStyles
} from "@material-ui/core";
import { slugify } from "../../../utils";
import clsx from "clsx";
import { COLORS, FONTS } from "../../../theme";

const TrackerCard = ({
	classes,
	className,
	index,
	name,
	description,
	thumb,
	status,
	slug,
	...props
}) => {
	return (
		<Card className={clsx(classes.root, className, classes[status])} {...props}>
			<Container maxWidth="md" spacing={3}>
				<Grid
					container
					spacing={0}
					style={{
						flexDirection: index % 2 === 0 ? "row" : "row-reverse"
					}}
				>
					<Grid item xs={12} md={5}>
						{thumb ? <CardMedia component="img" image={thumb} alt="" /> : null}
					</Grid>
					<Grid item xs={12} md={7}>
						<CardContent>
							{status === "inactive" ? (
								<Typography component="div" variant="h6">
									Up next
								</Typography>
							) : (
								""
							)}
							<Typography component="h2" variant="h2" gutterBottom>
								{name}
							</Typography>
							<Typography paragraph>{description}</Typography>
							{status === "active" ? (
								<CardActions>
									<Link to={`/data/${slug}`}>
										<Button
											role="presentation"
											tabIndex={-1}
											variant="contained"
										>
											{`Go to ${name.toLowerCase()}`}
										</Button>
									</Link>
								</CardActions>
							) : null}
						</CardContent>
					</Grid>
				</Grid>
			</Container>
		</Card>
	);
};

Card.propTypes = {};

export default withStyles(theme => {
	return {
		root: {
			display: "flex",
			flexDirection: "column",
			borderRadius: 0,
			boxShadow: "none",
			"& .MuiContainer-root": {
				padding: theme.spacing(0, 2),
				[theme.breakpoints.up("sm")]: {
					padding: theme.spacing(0, 3)
				}
			},
			"& .MuiGrid-item": {
				padding: 0,
				"&:first-child": {
					display: "flex",
					alignItems: "center",
					justifyContent: "center"
				}
			},
			"& .MuiCardContent-root": {
				height: "100%",
				display: "flex",
				flexWrap: "wrap",
				flexDirection: "row",
				alignContent: "center"
			},
			"& .MuiTypography-h2": {
				fontSize: theme.typography.pxToRem(40)
			},
			"& .MuiCardActions-root": {
				width: "100%",
				padding: 0,
				"& a": {
					textDecoration: "none"
				}
			},
			"& .MuiCardMedia-root": {
				[theme.breakpoints.down("sm")]: {
					display: "none"
				}
			},
			"& .MuiCardMedia-img": {
				width: "100%",
				height: "100%",
				objectFit: "contain"
			},
			"& .MuiTypography-h6": {
				width: "100%",
				marginBottom: theme.spacing(2),
				color: theme.palette.red.main
			}
		},
		active: {
			"& *": {
				color: theme.palette.text.light
			},
			"& > .MuiContainer-root > .MuiGrid-root": {
				background: "linear-gradient(268.35deg, #001F60 4.26%, #05122F 86.72%)"
			},
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(12),
				paddingBottom: theme.spacing(12),
				[theme.breakpoints.down("sm")]: {
					padding: theme.spacing(10, 8)
				}
			}
		},
		inactive: {
			backgroundColor: theme.palette.background.paperSecondary
		}
	};
})(TrackerCard);
