import React, { useRef, useState, useEffect } from "react";
import clsx from "clsx";
import {
	Button,
	FormControlLabel,
	Grid,
	Switch,
	withStyles
} from "@material-ui/core";
import { TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateSelector, Toggle, TooltipWrapper } from "../../common";
import ChartTooltip from "./tooltip";

const MONTHS = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"
];
const MONTHLY_START_DATE_REWIND = 10;
const WEEKLY_START_DATE_REWIND = 5;

const START_DATE_REWIND = {
	monthly: MONTHLY_START_DATE_REWIND,
	weekly: WEEKLY_START_DATE_REWIND
};

const MONTHLY_TOOLTIP = "";
const WEEKLY_TOOLTIP = "";

const FILINGS_TOOLTIP =
	"An eviction filing is the first documented step of the formal eviction process occurs when a landlord or property manager files an eviction case with a court. Eviction filings are counted in the week or month in which the case is filed.";
const DEFAULTS_TOOLTIP =
	"A default judgment occurs when the court rules in favor of the plaintiff (landlord) without hearing the eviction case because the defendant (tenant) did not respond to the eviction summons as required. Default judgments are counted in the month in which the case is judged (not the month in which the case is filed).";
const COUNTS_TOOLTIP = "";
const PERCENT_HISTORICAL_TOOLTIP =
	"For filings, this value is the monthly filings as a percent of pre-pandemic average for that month. For defaults, this value is the percent of judgments that went to default in this month.";

const TOOLTIPS = {
	monthly: MONTHLY_TOOLTIP,
	weekly: WEEKLY_TOOLTIP,
	filings: FILINGS_TOOLTIP,
	defaults: DEFAULTS_TOOLTIP,
	counts: COUNTS_TOOLTIP,
	comparisons: PERCENT_HISTORICAL_TOOLTIP
};

const INTERVAL_OPTIONS = [
	{
		key: "monthly",
		label: "Monthly",
		tooltip: MONTHLY_TOOLTIP
	},
	{
		key: "weekly",
		label: "Weekly",
		tooltip: WEEKLY_TOOLTIP
	}
];

const VARIABLE_OPTIONS = {
	monthly: [
		{
			key: "filings",
			label: "Filings",
			tooltip: FILINGS_TOOLTIP
		},
		{
			key: "defaults",
			label: "Defaults",
			tooltip: DEFAULTS_TOOLTIP
		}
	],
	weekly: [
		{
			key: "filings",
			label: "Filings",
			tooltip: FILINGS_TOOLTIP
		},
		{
			key: "defaults",
			label: "Defaults",
			tooltip: DEFAULTS_TOOLTIP
		}
	]
};

const UNIT_OPTIONS = {
	monthly: [
		{
			key: "count",
			label: "Counts",
			tooltip: COUNTS_TOOLTIP
		},
		{
			key: "comparison",
			label: "Comparison",
			tooltip: PERCENT_HISTORICAL_TOOLTIP
		}
	],
	weekly: [
		{
			key: "count",
			label: "Counts",
			tooltip: COUNTS_TOOLTIP
		},
		{
			key: "comparison",
			label: "Comparison",
			tooltip: PERCENT_HISTORICAL_TOOLTIP
		}
	]
};

const OPTIONS = {
	monthly: [
		{
			key: "filings",
			label: "Filings",
			options: [
				{
					key: "count",
					label: "Filings Counts"
				},
				{
					key: "comparison",
					label: "Filings Rates"
				}
			]
		},
		{
			key: "judgements",
			label: "Judgments",
			options: [
				{
					key: "count",
					label: "Judgments Counts"
				}
			]
		},
		{
			key: "defaults",
			label: "Defaults",
			options: [
				{
					key: "count",
					label: "Defaults Counts"
				},
				{
					key: "comparison",
					label: "Defaults Rates"
				}
			]
		}
	],
	weekly: [
		{
			key: "filings",
			label: "Filings",
			options: [
				{
					key: "count",
					label: "Filings Counts"
				},
				{
					key: "comparison",
					label: "Filings Rates"
				}
			]
		}
	]
};

const ChartToolbar = ({
	classes,
	className,
	level,
	settings,
	setSettings,
	minDate,
	maxDate,
	...props
}) => {
	const [interval, setInterval] = useState(settings.interval);
	const [variable, setVariable] = useState(settings.variable);
	const [unit, setUnit] = useState(settings.unit);

	useEffect(() => {
		const newStartDate = new Date(
			new Date(maxDate).setMonth(
				maxDate.getMonth() - START_DATE_REWIND[settings.interval]
			)
		);
		const newEndDate = maxDate;
		setSettings({
			interval: interval,
			variable: variable,
			unit: unit,
			startDate: newStartDate,
			endDate: newEndDate,
			showAvg: true
		});
	}, []);

	const onToggleChange = (e, val, variable) => {
		setSettings({
			...settings,
			[variable]: val
		});
	};

	const onSwitch = e => {
		setSettings({
			...settings,
			showAvg: e.target.checked
		});
	};

	const onStartDateChange = val => {
		setSettings({
			...settings,
			startDate: val
		});
	};

	const onEndDateChange = val => {
		setSettings({
			...settings,
			endDate: val
		});
	};

	// const DateRange = ({ startDate, endDate }) => {
	// 	return (
	// 		<LocalizationProvider
	// 			dateAdapter={AdapterDateFns}>
	// 			<DesktopDatePicker
	//        label="Start"
	//        inputFormat="MM/dd/yyyy"
	//        value={startDate}
	//        onChange={onStartDateChange}
	//        renderInput={(params) => <TextField {...params} />} />
	//      <DesktopDatePicker
	//        label="End"
	//        inputFormat="MM/dd/yyyy"
	//        value={endDate}
	//        onChange={onEndDateChange}
	//        renderInput={(params) => <TextField {...params} />} />
	//     </LocalizationProvider>
	// 	);
	// };

	return (
		<div className={clsx(classes.root, className)}>
			<Grid container spacing={2}>
				<Grid item>
					<Toggle
						variable="interval"
						value={settings.interval}
						options={INTERVAL_OPTIONS}
						onChange={(e, val) => {
							onToggleChange(e, val, "interval");
						}}
					/>
				</Grid>

				<Grid item>
					<Toggle
						variable="variable"
						value={settings.variable}
						options={VARIABLE_OPTIONS[settings.interval]}
						onChange={(e, val) => {
							onToggleChange(e, val, "variable");
						}}
					/>
				</Grid>

				<Grid item>
					<Toggle
						variable="unit"
						value={settings.unit}
						options={UNIT_OPTIONS[settings.interval]}
						onChange={(e, val) => {
							onToggleChange(e, val, "unit");
						}}
					/>
				</Grid>

				<Grid item>
					<Grid container spacing={2}>
						{level === "county" ? (
							<Grid item>
								<TooltipWrapper
									arrow
									content={
										settings.unit !== "comparison"
											? "Only available for historical comparison"
											: null
									}
								>
									<FormControlLabel
										// value="showAvg"
										label="Show state average"
										labelPlacement="start"
										disabled={settings.unit !== "comparison"}
										control={
											<Switch
												color="primary"
												value={settings.showAvg}
												onChange={onSwitch}
											/>
										}
									/>
								</TooltipWrapper>
							</Grid>
						) : (
							""
						)}

						<Grid item>
							<DateSelector
								minDate={minDate}
								maxDate={maxDate}
								startDate={settings.startDate}
								endDate={settings.endDate}
								onStartDateChange={onStartDateChange}
								onEndDateChange={onEndDateChange}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

ChartToolbar.defaultProps = {};
ChartToolbar.propIntervals = {};

export default withStyles(theme => ({
	root: {
		marginBottom: theme.spacing(6),
		"& .MuiFormControlLabel-root": {
			marginLeft: 0,
			marginRight: 0
		}
	}
}))(ChartToolbar);
