import React from "react";
import clsx from "clsx";
import { Box, Tooltip as MuiTooltip, withStyles } from "@material-ui/core";
import Tooltip from "../tooltip";

const TooltipWrapper = ({
	content,
	icon,
	children,
	classes,
	className,
	light,
	...props
}) => {
	const { tooltip, arrow, lightTooltip, lightArrow } = classes;
	return (
		<Box className={clsx(classes.root, className)}>
			{content ? (
				<MuiTooltip
					title={content ? <Tooltip>{content}</Tooltip> : ""}
					arrow={content ? true : false}
					classes={{
						tooltip: light ? lightTooltip : tooltip,
						arrow: light ? lightArrow : arrow
					}}
				>
					<Box>
						{children}
						{children && icon ? " " : ""}
						{icon ? <img src="/images/icon-info.svg" alt="" /> : ""}
					</Box>
				</MuiTooltip>
			) : (
				<Box>{children}</Box>
			)}
		</Box>
	);
};

TooltipWrapper.propTypes = {};

export default withStyles(theme => ({
	root: {
		display: "inline",
		"& > div": {
			display: "inline"
		},
		"& img": {
			position: "relative",
			top: 2,
			"&:not(:first-child)": {
				marginLeft: theme.spacing(5)
			}
		}
	},
	tooltip: {
		padding: 0
	},
	arrow: {
		color: theme.palette.blue.dark
	},
	lightTooltip: {
		padding: 0,
		"& > div": {
			color: theme.palette.text.primary,
			backgroundColor: theme.palette.background.paper
		}
	},
	lightArrow: {
		color: theme.palette.background.paper
	}
}))(TooltipWrapper);
