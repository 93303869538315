import React from "react";
import clsx from "clsx";
import { Box, Grid, Typography, withStyles } from "@material-ui/core";
import { formatNumber } from "../../../utils";
import Tooltip from "../../common/tooltip";

const FILINGS_RATE_LABEL = "Filings rate";
const FILINGS_LATEST_LABEL = "Latest month's filings";

const MapTooltip = ({
	classes,
	className,
	level,
	x,
	y,
	data,
	visible,
	...props
}) => {
	return (
		<Tooltip
			className={clsx(classes.root, className)}
			style={{
				left: x,
				top: y,
				opacity: visible ? 1 : 1
			}}
			{...props}
			header={data.name}
		>
			{data.filings ? (
				<Box className={classes.stat}>
					<Grid container>
						<Grid item className={classes.value}>
							<Typography component="span">
								{formatNumber(data.filings)}%
							</Typography>
						</Grid>
						<Grid item className={classes.unit}>
							<Typography component="div">
								eviction filing rate (per 100 renter households)
							</Typography>
						</Grid>
					</Grid>
				</Box>
			) : (
				""
			)}
			{level === "nation" && data.counties_tracked && data.counties_total ? (
				<Box className={classes.stat}>
					<Grid container>
						<Grid item className={classes.value}>
							<Typography component="span">
								{data.counties_tracked}/{data.counties_total}
							</Typography>
						</Grid>
						<Grid item className={classes.unit}>
							<Typography component="div">counties tracked</Typography>
						</Grid>
					</Grid>
				</Box>
			) : null}

			{data.filings_count ? (
				<Typography component="em" className={classes.prompt}>
					Click to learn more
				</Typography>
			) : data.type !== "service-area" ? (
				<Typography className={classes.empty}>
					No data currently available
				</Typography>
			) : null}
		</Tooltip>
	);
};

MapTooltip.propTypes = {};

export default withStyles(theme => ({
	root: {
		minWidth: 200,
		position: "absolute",
		zIndex: 1,
		transform: `translate(${theme.spacing(2)}, ${theme.spacing(2)})`
	},
	stat: {
		marginBottom: theme.spacing(1),
		"& .MuiGrid-container": {
			flexWrap: "nowrap"
		}
	},
	value: {
		display: "inline-block",
		paddingRight: theme.spacing(1)
	},
	unit: {
		maxWidth: theme.spacing(14),
		margin: "auto 0",
		"& .MuiTypography-root": {
			fontSize: theme.typography.pxToRem(10),
			fontWeight: theme.typography.body.semibold.fontWeight
		}
	},
	prompt: {
		fontSize: theme.typography.pxToRem(10),
		fontWeight: theme.typography.body.semibold.fontWeight,
		color: theme.palette.blue.light
	},
	empty: {
		fontSize: theme.typography.pxToRem(10),
		fontWeight: theme.typography.body.semibold.fontWeight
	}
}))(MapTooltip);
