import React from "react";
import clsx from "clsx";
import { Box, SvgIcon, Typography, withStyles } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

const BurgerIcon = ({ classes, className, ...props }) => {
	return (
		<Box className={clsx(classes.root, className)}>
			<MenuIcon />
		</Box>
	);
};

export default withStyles(theme => ({
	root: {
		fontSize: theme.typography.pxToRem(14),
		letterSpacing: 1
	}
}))(BurgerIcon);
