import { withStyles } from "@material-ui/core";
import DesktopNavigation from "gatsby-theme-hypersite/src/header/desktop-navigation";

export default withStyles(theme => ({
	root: {
		height: "100%",
		backgroundColor: "transparent"
	},
	list: {
		"nav > &": {
			height: "100%"
		},
		"div > &": {
			backgroundColor: theme.palette.background.paperSecondary
		}
	},
	listItem: {
		backgroundColor: "transparent",
		"& .HypNavigation-depth1": {
			minWidth: "100%"
		}
	},
	link: {
		height: "100%",
		color: theme.palette.blue.dark,
		textDecoration: "none",
		...theme.typography.body.semibold,
		transition: theme.transitions.create(["background-color"]),
		'&[href*="http"]': {
			paddingRight: theme.spacing(4),
			backgroundPosition: "calc(100% - .5rem) center",
			backgroundRepeat: "no-repeat",
			backgroundSize: "1em",
			backgroundImage: "url(/images/icon-launch-blue.svg)",
			"&:hover": {
				backgroundPosition: "calc(100% - .5rem) center",
				backgroundRepeat: "no-repeat",
				backgroundSize: "1em",
				backgroundImage: "url(/images/icon-launch-red.svg)"
			}
		},
		"&:hover, &:focus-within": {
			backgroundColor: "",
			color: theme.palette.red.main,
			textDecoration: "underline",
			textUnderlineOffset: "6px"
		},
		"nav > $list > $listItem > &, nav > $list > $listItem:hover > &, nav > $list > $listItem:focus-within > &":
			{
				paddingTop: theme.spacing(4),
				backgroundSize: theme.spacing(3),
				backgroundPositionX: "center",
				backgroundPositionY: theme.spacing(2),
				backgroundRepeat: "no-repeat"
			},
		"$listItem:hover > &, $listItem:focus-within > &": {
			backgroundColor: theme.palette.background.paperSecondary
		},
		"nav > ul > li > &, nav > ul > $listItem:hover > &, nav > ul > $listItem:focus-within > &":
			{
				pointerEvents: "none",
				cursor: "default",
				'&[href="#about"]': {
					backgroundImage: "url(/images/icon-menu-about.svg)"
				},
				'&[href="#data"]': {
					backgroundImage: "url(/images/icon-menu-data.svg)"
				},
				'&[href="#research"]': {
					backgroundImage: "url(/images/icon-menu-research.svg)"
				}
			}
	}
}))(DesktopNavigation);
