import React from "react";
import Hero from "gatsby-theme-hypersite/src/main/hero";
import { Typography, withStyles } from "@material-ui/core";

const JurisdictionHero = ({ classes, children, ...props }) => {
	return (
		<Hero
			{...props}
			classes={classes}
			color="text.light"
			bgcolor="transparent"
			alt=""
		>
			{children}
		</Hero>
	);
};

export default withStyles(theme => ({
	root: {
		position: "relative",
		backgroundColor: theme.palette.blue.dark,
		"& *": {
			color: theme.palette.text.light
		},
		"& h1": {
			marginBottom: theme.spacing(2)
		},
		"& .MuiBreadcrumbs-root": {
			position: "absolute",
			top: theme.spacing(6),
			left: theme.spacing(6)
		},
		"& a.mapboxgl-ctrl-logo": {
			display: "none"
		}
	},
	container: {
		marginTop: "auto",
		display: "flex",
		flexDirection: "row-reverse"
	},
	content: {
		width: "50%",
		position: "static",
		flex: 1,
		paddingLeft: theme.spacing(6),
		[theme.breakpoints.down("md")]: {
			paddingLeft: theme.spacing(0)
		},
		[theme.breakpoints.down("sm")]: {
			paddingTop: theme.spacing(10)
		}
	},
	imageWrapper: {
		width: "50%",
		padding: theme.spacing(0, 2, 0, 0),
		flex: 1,
		"& > div": {
			minHeight: 400
		},
		"& .mapboxgl-map": {
			height: 400
		},
		[theme.breakpoints.down("sm")]: {
			display: "none"
		}
	},
	image: {
		width: "100%"
	}
}))(JurisdictionHero);
