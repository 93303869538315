import React from "react";
import clsx from "clsx";
import { MDXRenderer } from "gatsby-plugin-mdx";
// import { default as BaseLayout } from "gatsby-theme-hypersite/src/layout";
import { default as BaseLayout } from "gatsby-theme-hypersite/src/layout";
import { Helmet } from "react-helmet";
import { createStyles, makeStyles, withStyles } from "@material-ui/core";
import "@reach/skip-nav/styles.css";
// import { ThemeProvider } from "@material-ui/styles";
import { Content, JumpLinks, PageHero } from "../components";

// const useStyles = makeStyles(theme =>
// 	createStyles({
// 		"@global": {
// 			add padding to make room for header on
// 			first element of the page content
// 			"#reach-skip-nav + *": {
// 			  paddingTop: theme.spacing(16),
// 			},
// 		}
// 	})
// );

const Layout = ({
	HeroComponent,
	heroProps,
	sections,
	children,
	classes,
	...props
}) => {
	let frontmatter;
	if (props?.pageContext?.frontmatter) {
		frontmatter = props?.pageContext?.frontmatter;
	}
	if (props?.data?.mdx?.frontmatter) {
		frontmatter = props.data.mdx.frontmatter;
	}

	const Hero = HeroComponent ? HeroComponent : PageHero;
	const heroClasses = classes
		? (({ root, container, content, imageWrapper, image }) => ({
				root,
				container,
				content,
				imageWrapper,
				image
		  }))(classes)
		: {};

	return (
		<BaseLayout className={classes.root} {...props}>
			<Helmet
				htmlAttributes={{
					lang: "en"
				}}
				bodyAttributes={{
					class: clsx(classes.root, sections ? classes.hasJumpLinks : null)
				}}
			>
				<link href="/fonts/stylesheet.css" rel="stylesheet" />
				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href="/images/favicons/apple-touch-icon.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="32x32"
					href="/images/favicons/favicon-32x32.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href="/images/favicons/favicon-16x16.png"
				/>
				<link
					rel="mask-icon"
					href="/images/favicons/safari-pinned-tab.svg"
					color="#2d2f85"
				/>
				<link rel="shortcut icon" href="/images/favicons/favicon.ico" />
				<link rel="manifest" href="/site.webmanifest" />
			</Helmet>
			{sections ? <JumpLinks sections={sections} /> : ""}
			{HeroComponent ? (
				HeroComponent
			) : (
				<PageHero
					title={props.title ? props.title : frontmatter?.name}
					lede={props.lede ? props.lede : frontmatter?.meta?.description}
					image={props.image ? props.image : frontmatter?.hero}
					location={props.location}
					classes={heroClasses}
					gradientVariant={
						props.gradientVariant ? props.gradientVariant : false
					}
					{...heroProps}
				/>
			)}
			{children}
		</BaseLayout>
	);
};

export default withStyles(theme => ({
	root: {},
	container: {},
	content: {},
	imageWrapper: {},
	image: {},
	hasJumpLinks: {
		"& .HypBreadcrumb-root": {
			// top: theme.layout.headerHeight,
			top: 90
		},
		"& header.HypHeader-root": {
			boxShadow: "none"
		}
	}
}))(Layout);
